@import "themes";
//colors used in latest UI changes
$silver: #c7c7c7;
$doveGray: #707070;
$white: #ffffff;
$black: #333333;
$red: #f44336;
$tooltip: #414141;
$toggleGreen: #048b8e;
$gray: #c4c4c4;
$dottedUnderline: #ff6347;
$isabelline: #f0f0f0;
$menuHover: #e6e6e6;
$reepBackground: #e0ebff;
$platinum: #e8e8e8;
$liteGreen: #73ae61;
$shadeBlue: #385682;
$darkBlue: #2e2f5d;
$slider_bg: #1294aa;
$disclaimer: #797979;
$ghost_white: #f8f8ff;

$popup_bg: #eef8fd;
$popup_close: #a7ddf6;
$alert_bg: #dfe154;
$alert_reep: #048b8e;
$reep-border: #828282;
$reep-checkmark: #0e34a0;
$black40: #adadad;
$black50: #999999;
$black60: #858585;
$black80: #5c5c5c;
$toggleButtonBackground: #ececec;
$toggleButtonText: #c2c2c2;
$default_bar_bg: #ddd;
$learnMoreText: #232222;
$techBackground: #c4c4c44d;
$babyPowder: #f9f9f2;
$spaceCadet: #2f3061;
$backgroundBlack80: rgba(
  $color: $black,
  $alpha: 0.8,
);
$backgroundBlack30: rgba(
  $color: $black,
  $alpha: 0.3,
);
$backgroundGray10: rgba(
  $color: $gray,
  $alpha: 0.1,
);
$backgroundGray25: rgba(47, 48, 97, 0.25);

// dashboard card colors
$topaz: #dfe154;
$cyan_azure: #ea6c94;
$pistachio: #f7c4d4;
$light_hot_pink: #0069a1;
$maximum_blue_purple: #5bcada;
$dark_pastel_purple: #a67bed;
$tulip: #fec372;
$dark_terra_cotta: #69a8f7;
$white_smoke: #f5f6f7;
$Opal: #9ebec3;
$blond: #f7f0c4;

//updated color values for new UI look
$blizzardBlue: #a3e1eb;
$black60: #858585;
