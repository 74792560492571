@mixin breakpoint2($point) {
  @if $point == desktop {
    @media screen and (max-width: 1440px) {
      @content;
    }
  } @else if $point == laptop {
    @media screen and (max-width: 1280px) {
      @content;
    }
  } @else if $point == miniLaptop {
    @media screen and (max-width: 1024px) {
      @content;
    }
  } @else if $point == padTab {
    @media screen and (max-width: 992px) {
      @content;
    }
  } @else if $point == tablet {
    @media screen and (max-width: 768px) {
      @content;
    }
  } @else if $point == mobile {
    @media screen and (max-width: 600px) {
      @content;
    }
  }
}
