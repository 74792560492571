@import "yltn-theme";
@import "sass/index";

@font-face {
  font-family: "AvenirNext";
  src: url("../assets/font/AvenirNext/Avenir-Regular.otf") format("opentype");
}

@font-face {
  font-family: "BrandonGrotesque";
  src: url("../assets/font/BrandonGrotesque/BGreg.otf") format("opentype");
}

@font-face {
  font-family: "verdana";
  src: url("../assets/font/verdana/verdana.ttf") format("opentype");
}

@font-face {
  font-family: "Glacial Indifference";
  src: url("../assets/font/GlacialIndifference/GlacialIndifference-Regular.otf")
    format("opentype");
}

@media only screen and (min-width: 800px) {
  md-sidenav.mat-sidenav-opened,
  md-sidenav.mat-sidenav-opening {
    box-shadow: 0px 2px 1px -1px rgba($color: $black, $alpha: 0.8),
      0px 1px 1px 0px rgba($color: $black, $alpha: 0.8),
      0px 1px 3px 0px rgba($color: $black, $alpha: 0.8) !important;
  }
}

// this class is to override map autocoplete dropdown position
.pac-container {
  // margin-top: 16px;
  margin-top: 12px !important;
  margin-left: -14px;
  box-shadow: none !important;
  border: 1px solid $silver;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $fontFamily;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  min-height: 100%;
  font-family: $fontFamily;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1 {
  letter-spacing: -0.02em;
}

ul {
  padding-left: 40px;
  margin-block: 1em;
}

a {
  font-weight: 500;
  cursor: pointer;
}

.cdk-overlay-backdrop.dark-backdrop {
  background: $black;
  opacity: 0.8;
  border-radius: 5px;
}

.mdc-button {
  letter-spacing: 0.05em;
}

.mat-mdc-raised-button.mat-accent,
.mat-mdc-raised-button.mat-accent:not(:disabled) {
  height: 48px;
  width: 100%;
  max-width: 223px;
  background-color: $primaryButton;
  border: 1px solid $buttonBorder;
  border-radius: 5px;
  letter-spacing: 0.05em;
  color: $primaryButton_text;
  cursor: pointer;
  font: bold var(--button-fontSize) / 1.5 $fontFamily;
  &:hover {
    background-color: $primaryButton_hover;
    color: $primaryButton_text_hover;
    border: 2px solid $buttonBorder_hover;
  }
  @include breakpoint2(mobile) {
    width: 100%;
    font-size: 16px;
    line-height: 18px;
  }
}

.mat-mdc-raised-button:not(.mdc-ripple-upgraded):focus,
.mat-mdc-raised-button:not(:disabled),
.mat-mdc-raised-button.mat-accent:not(.mdc-ripple-upgraded):focus,
.mat-mdc-raised-button.mat-accent:not(:disabled) {
  box-shadow: none;
  .mat-mdc-button-touch-target {
    height: inherit;
  }
  &:hover {
    box-shadow: none;
  }
}

.dialogBox .mat-mdc-raised-button.mat-accent {
  font-size: 18px;
}

.mat-mdc-raised-button.mat-accent[disabled] {
  background-color: $silver;
  color: $white;
  border: none;
}

// tooltip message
.mat-mdc-tooltip {
  & > div {
    opacity: 1;
    padding: 16px;
    max-height: 50vh;
    font-size: 14px !important;
    font-family: $fontFamily !important;
    line-height: 1.5 !important;
    background-color: $tooltip !important;
  }
}

.mat-icon {
  color: $black60;
  font-size: 16px;
  cursor: pointer;
  vertical-align: middle;
}

//snack bar button colour change
.mdc-snackbar.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: $already_have;
}

// Osano Cookie
.osano-cm-storage-policy,
.osano-cm-content__link,
.osano-cm-link,
.osano-cm-widget {
  display: none !important;
}

.yt_link {
  color: $link;
}
.mat-sidenav-container .mat-drawer-content {
  overflow-x: hidden;
}

// Width of a image of refer friend
.referFriend-img_width {
  width: 100px;
}
.mat-option-text {
  font-size: 14px;
}

// Remove underline below the mat-tab header options
.mat-tab-header {
  border-bottom: none;
}

// Mat Snack Bar for Error
.mat-snack-bar-container {
  &.error_snackBar {
    background-color: $red;
    color: $white;
    .mat-button-wrapper {
      color: $black;
      font-weight: 600;
    }
  }
}

// Mat-select drop-down
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  // width: fit-content !important; // overriding inline material style
  .mat-mdc-option,
  .mdc-list-item--selected:not(.mdc-list-item--disabled) {
    .mdc-list-item__primary-text {
      font-family: $fontFamily;
      color: $black;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
