$fontFamily: "Open Sans", sans-serif;
//Themes
.reep_theme {
  --leftNav: #2f3061;
  --primaryButton: #f8c113;
  --primaryButton_hover: #fad45a;
  --ghostButton: #f8c113;
  --primaryButton_text: #333333;
  --primaryButton_text_hover: #333333;
  --ghostButton_text: #333333;
  --buttonBorder: #f8c113;
  --buttonBorder_hover: #fad45a;
  --link: #0e34a0;
  --checkBox_outline: #0e34a0;
  --done_icon: #4a39e5;
  --box_border: #6677ff;
  --tab_header: #6677ff;
  --already_have: #6677ff;
  --interested: #c2c9ff;
  --current: #4a39e5;
  --complete: #4a39e5;
  --disabled-step: #eeeeee;
  --segmentor_line: #6677ff;
  --usageChart: #6677ff;
  --usageChartHover: #c2c9ff;
  --footer: #4a39e5;
  --footer_text: #ffffff;
  --fontFamily: "Open Sans", sans-serif;
}
.sunw_theme {
  --leftNav: #ffffff;
  --primaryButton: #1f326e;
  --primaryButton_hover: #007cba;
  --ghostButton: #1f326e;
  --primaryButton_text: #ffffff;
  --primaryButton_text_hover: #ffffff;
  --ghostButton_text: #1f326e;
  --buttonBorder: #1f326e;
  --buttonBorder_hover: #007cba;
  --link: #006cff;
  --checkBox_outline: #006cff;
  --done_icon: #006cff;
  --box_border: #ffa852;
  --tab_header: #ffa852;
  --already_have: #ffa852;
  --interested: #ffdcba;
  --current: #1f326e;
  --complete: #000000;
  --disabled-step: #707070;
  --segmentor_line: #ffa852;
  --usageChart: #ffa852;
  --usageChartHover: #ffdcba;
  --footer: #1f326e;
  --footer_text: #ffffff;
  --fontFamily: "Roboto", sans-serif;
}
.evma_theme {
  --leftNav: #ffffff;
  --primaryButton: #ff6900;
  --primaryButton_hover: #ffffff;
  --ghostButton: #ff6900;
  --primaryButton_text: #ffffff;
  --primaryButton_text_hover: #ff6900;
  --ghostButton_text: #ff6900;
  --buttonBorder: #ff6900;
  --buttonBorder_hover: #ff6900;
  --link: #007c89;
  --checkBox_outline: #007c89;
  --done_icon: #007c89;
  --box_border: #1c86c2;
  --tab_header: #1c86c2;
  --already_have: #1c86c2;
  --interested: #e1f6fa;
  --current: #ff6900;
  --complete: #000000;
  --disabled-step: #707070;
  --segmentor_line: #1c86c2;
  --usageChart: #1c86c2;
  --usageChartHover: #e1f6fa;
  --footer: #ff6900;
  --footer_text: #ffffff;
  --fontFamily: "Lato", sans-serif;
}
.usgb_theme {
  --leftNav: #cdba48;
  --primaryButton: #cdba48;
  --primaryButton_hover: #dccf7f;
  --ghostButton: #cdba48;
  --primaryButton_text: #36312f;
  --primaryButton_text_hover: #36312f;
  --ghostButton_text: #36312f;
  --buttonBorder: #cdba48;
  --buttonBorder_hover: #dccf7f;
  --link: #8f7335;
  --checkBox_outline: #8f7335;
  --done_icon: #8f7335;
  --box_border: #aaab60;
  --tab_header: #aaab60;
  --already_have: #aaab60;
  --interested: #d5d5b0;
  --current: #36312f;
  --complete: #000000;
  --disabled-step: #707070;
  --segmentor_line: #aaab60;
  --usageChart: #aaab60;
  --usageChartHover: #d5d5b0;
  --footer: #cdba48;
  --footer_text: #36312f;
  --fontFamily: "Poppins", sans-serif;
}
.work_theme {
  --leftNav: #ffffff;
  --primaryButton: #005cb9;
  --primaryButton_hover: #0875e1;
  --ghostButton: #005cb9;
  --primaryButton_text: #ffffff;
  --primaryButton_text_hover: #ffffff;
  --ghostButton_text: #005cb9;
  --buttonBorder: #005cb9;
  --buttonBorder_hover: #0875e1;
  --link: #0875e1;
  --checkBox_outline: #0875e1;
  --done_icon: #0875e1;
  --box_border: #f38b00;
  --tab_header: #f38b00;
  --already_have: #f38b00;
  --interested: #ffc629;
  --current: #005cb9;
  --complete: #000000;
  --disabled-step: #707070;
  --segmentor_line: #f38b00;
  --usageChart: #f38b00;
  --usageChartHover: #ffc629;
  --footer: #005cb9;
  --footer_text: #ffffff;
  --fontFamily: "Open Sans", sans-serif;
}
.wspp_theme {
  --leftNav: #d8e5f0;
  --primaryButton: #537898;
  --primaryButton_hover: #3d5f7a;
  --ghostButton: #537898;
  --primaryButton_text: #ffffff;
  --primaryButton_text_hover: #ffffff;
  --ghostButton_text: #537898;
  --buttonBorder: #537898;
  --buttonBorder_hover: #3d5f7a;
  --link: #333d47;
  --checkBox_outline: #333d47;
  --done_icon: #333d47;
  --box_border: #537898;
  --tab_header: #537898;
  --already_have: #537898;
  --interested: #d8e5f0;
  --current: #ff372f;
  --complete: #000000;
  --disabled-step: #707070;
  --segmentor_line: #537898;
  --usageChart: #537898;
  --usageChartHover: #d8e5f0;
  --footer: #d8e5f0;
  --footer_text: #000000;
  --fontFamily: "Montserrat", sans-serif;
}
.acte_theme {
  --primaryButton: #f37321;
  --primaryButton_hover: #f79d64;
  --ghostButton: #f37321;
  --primaryButton_text: #000000;
  --primaryButton_text_hover: #000000;
  --ghostButton_text: #000000;
  --buttonBorder: #f37321;
  --buttonBorder_hover: #f79d64;
  --link: #8da335;
  --checkBox_outline: #8da335;
  --done_icon: #8da335;
  --box_border: #3a7998;
  --tab_header: #3a7998;
  --already_have: #3a7998;
  --interested: #9dbccc;
  --current: #8da335;
  --complete: #8da335;
  --disabled-step: #c7c7c7;
  --segmentor_line: #3a7998;
  --usageChart: #3a7998;
  --usageChartHover: #9dbccc;
  --footer: #f37321;
  --footer_text: #000000;
  --fontFamily: "Glacial Indifference", sans-serif;
}
.eart_theme {
  --primaryButton: #8cc63e;
  --primaryButton_hover: #afd778;
  --ghostButton: #8cc63e;
  --primaryButton_text: #000000;
  --primaryButton_text_hover: #000000;
  --ghostButton_text: #000000;
  --buttonBorder: #8cc63e;
  --buttonBorder_hover: #afd778;
  --link: #4d656c;
  --checkBox_outline: #4d656c;
  --done_icon: #4d656c;
  --box_border: #5f666a;
  --tab_header: #5f666a;
  --already_have: #5f666a;
  --interested: #bdccd4;
  --current: #4d656c;
  --complete: #4d656c;
  --disabled-step: #c7c7c7;
  --segmentor_line: #5f666a;
  --usageChart: #5f666a;
  --usageChartHover: #bdccd4;
  --footer: #036a36;
  --footer_text: #ffffff;
  --fontFamily: "Poppins", sans-serif;
}
// SCSS color variables
$leftNav: var(--leftNav);
$primaryButton: var(--primaryButton);
$primaryButton_hover: var(--primaryButton_hover);
$ghostButton: var(--ghostButton);
$primaryButton_text: var(--primaryButton_text);
$primaryButton_text_hover: var(--primaryButton_text_hover);
$ghostButton_text: var(--ghostButton_text);
$buttonBorder: var(--buttonBorder);
$buttonBorder_hover: var(--buttonBorder_hover);
$link: var(--link);
$checkBox_outline: var(--checkBox_outline);
$done_icon: var(--done_icon);
$box_border: var(--box_border);
$tab_header: var(--tab_header);
$already_have: var(--already_have);
$interested: var(--interested);
$disabled-step: var(--disabled-step);
$current: var(--current);
$complete: var(--complete);
$segmenter_line: var(--segmentor_line);
$usageChart: var(--usageChart);
$usageChartHover: var(--usageChartHover);
$footer: var(--footer);
$footerText: var(--footer_text);
$fontFamily: var(--fontFamily);
