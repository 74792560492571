.page-container {
  min-height: calc(100vh - 77px);
  position: relative;
}
.padding-class {
  padding: 45px 10% 83px 10%;
}
.padding-back-button {
  padding: 20px 10% 83px 10%;
}
.padding-93-class {
  padding: 93px 10% 83px 10%;
}
.padding-15-class {
  padding: 45px 15% 150px 15%;
}
.padding-45-class-solar {
  @media screen and (max-width: 424px) {
    padding: 45px 5% 150px 5%;
  }
}
.yt_maxWidth {
  margin: 0 auto;
  max-width: 1200px;
}
.yt_maxWidth-setting {
  margin: 0 auto;
  max-width: 917px;
}
.footer-container {
  height: 67px;
  width: 100%;
  bottom: 0;
}
.tour-guide-focus {
  z-index: 6;
  position: relative;
  background: $white;
  border: 2px solid $primaryButton;
}
button.tour-guide-focus {
  background: $primaryButton;
  color: $primaryButton_text;
}
