:root {
  --header-fontSize: 40px;
  --subheader-fontSize: 18px;
  --question-header-fontSize: 28px;
  --button-fontSize: 20px;
  --card-label-fontSize: 14px;
  --back-fontSize: 32px;
  --label-fontSize: 18px;
  --option-fontSize: 16px;
  --sidenav-fontSize: 18px;
  --mat-error-fontSize: 14px;
  --panel-title-fontSize: 24px;
  --header-tab-fontSize: 24px;
}

$topNavShadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 10%);
